import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	IconButton,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { H3 } from "components/Typography";
import React from "react";

const ContentAccordion = ({
	content,
	title,
	TitleVariant = H3,
	elevation = 1,
	TitleComponent = "div",
}: {
	content: React.ReactNode;
	title: string;
	TitleVariant?: React.ElementType;
	elevation?: number;
	TitleComponent?: React.ElementType;
}) => {
	return (
		<Box
			sx={{
				my: 4,
			}}>
			<Accordion
				elevation={elevation}
				sx={{
					"& .MuiAccordionSummary-root":
						{
							py: 1,
						},
				}}>
				<AccordionSummary
					expandIcon={
						<IconButton>
							<ExpandMoreIcon />
						</IconButton>
					}
					aria-controls='category-content-content'
					id='category-content-header'>
					<TitleVariant
						fontWeight='500'
						component={TitleComponent}>
						{title}
					</TitleVariant>
				</AccordionSummary>
				<AccordionDetails>
					{content}
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default ContentAccordion;
