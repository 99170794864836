"use client";

import { H6 } from "components/Typography";
import useFashionPreference from "hooks/useFashionPreference";
import { ProductCategory } from "models/ProductCategory.model";
import ContentAccordion from "pages-sections/listing/ContentAccordion";
import { SECTION_NAME } from "utils/config";
import FooterTableOfContent from "./FooterTableOfContent";

type Section = {
	id: string;
	name: string;
	handle: string;
};

type SectionsListProps = {};

const SectionsList =
	({}: SectionsListProps) => {
		const { fashionPreference } =
			useFashionPreference();

		if (!fashionPreference?.handle)
			return null;

		let sections: Section[] = [];

		sections = flattenCategories(
			fashionPreference
		);

		return (
			<ContentAccordion
				title={
					"Chaussures, vêtements et accessoires" +
					`${
						fashionPreference?.name
							? ` pour ${fashionPreference?.name}`
							: "plus de catégories"
					}`
				}
				content={
					fashionPreference.handle ===
						"enfant" ||
					fashionPreference.handle ===
						"bebe" ? (
						<>
							{fashionPreference?.category_children?.map(
								(child) => (
									<ContentAccordion
										key={child.id}
										elevation={0}
										TitleVariant={H6}
										title={child.name}
										content={
											<FooterTableOfContent
												content={flattenCategories(
													child
												)}
												urlRoot={`${SECTION_NAME}`}
											/>
										}
									/>
								)
							)}
						</>
					) : (
						<FooterTableOfContent
							content={sections ?? []}
							urlRoot={`${SECTION_NAME}`}
						/>
					)
				}
			/>
		);
	};

export default SectionsList;

const flattenCategories = (
	parentCategory: ProductCategory
) => {
	const sections: Section[] = [];

	for (const childCategory of parentCategory?.category_children) {
		sections.push({
			id: childCategory.id,
			name: childCategory.name,
			handle: childCategory.handle,
		});
		if (
			childCategory.category_children
		) {
			sections.push(
				...flattenCategories(
					childCategory
				)
			);
		}
	}
	return sections;
};
