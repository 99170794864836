import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/icons-material/ExpandMore.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Accordion/Accordion.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/AccordionDetails/AccordionDetails.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/AccordionSummary/AccordionSummary.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Grid/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/IconButton/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Skeleton/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Typography.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/pages-sections/index/SectionsList.tsx")