import {
	Box,
	Grid,
	Skeleton,
} from "@mui/material";
import {
	H1,
	H6,
} from "components/Typography";
import Link from "next/link";
import { FC } from "react";

export type ContentType = {
	id: string;
	name: string;
	handle: string;
};

export interface FooterTableOfContentProps {
	content: ContentType[];
	urlRoot?: string;
	isLoading?: boolean;
	title?: string;
}

const FooterTableOfContent: FC<
	FooterTableOfContentProps
> = ({
	content,
	urlRoot,
	isLoading = false,
	title,
}) => {
	if (
		!isLoading &&
		content.length === 0
	)
		return null;

	// sort content by name alphabetically
	const sortedContent = content.sort(
		(a, b) =>
			a.name.localeCompare(b.name)
	);

	return (
		<Box
			p={3}
			my={3}>
			{/* Title */}
			{isLoading ? (
				<Skeleton
					variant='text'
					width='80%'
					height={40}
					sx={{
						mb: 2,
					}}
				/>
			) : (
				<H1
					component='h3'
					mb={3}>
					{title}
				</H1>
			)}
			{/* Content Grid*/}
			<Grid
				container
				spacing={3}
				flexDirection='row'
				wrap='wrap'>
				{isLoading
					? [...Array(4)].map(
							(_, i) => {
								return (
									<Grid
										key={i}
										item
										lg={3}
										md={6}
										sm={6}
										xs={12}>
										<Skeleton
											variant='text'
											width='50%'
											height={30}
										/>
									</Grid>
								);
							}
					  )
					: sortedContent.map(
							(item) => {
								return (
									<Grid
										key={item.id}
										item
										lg={3}
										md={6}
										sm={6}
										xs={12}>
										<H6
											component='span'
											sx={{
												fontWeight:
													"normal",
											}}>
											<Link
												href={`/${urlRoot}/${item.handle}`}>
												{item.name}
											</Link>
										</H6>
									</Grid>
								);
							}
					  )}
			</Grid>
		</Box>
	);
};

export default FooterTableOfContent;
